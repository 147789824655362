import React from 'react'
import {
  Text,
  Container,
  Paper,
  Loader,
  createStyles,
  Anchor,
} from '@mantine/core'

interface AuthenticationStatusProps {
  loading: boolean
  error: Error | null
  authenticated: boolean
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background:
      localStorage.getItem('colorScheme') === 'light'
        ? 'rgb(248, 249, 250)'
        : 'rgb(36, 34, 57)',
  },
  paper: {
    boxShadow: theme.shadows.sm,
    padding: theme.spacing.lg,
    borderRadius: theme.radius.md,
    textAlign: 'center',
    maxWidth: 600,
    width: '100%',
    background: 'rgb(29, 30, 45)',
    color: '#fff',
    border: '0.0625rem solid rgb(77, 79, 102)',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  errorText: {
    fontWeight: 700,
    marginBottom: theme.spacing.md,
  },
  errorMessage: {
    marginBottom: theme.spacing.md,
  },
}))

const AuthenticationStatus: React.FC<AuthenticationStatusProps> = ({
  loading,
  error,
  authenticated,
}) => {
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      {loading && !error && (
        <div className={classes.loaderContainer}>
          <Loader size={65} color="blue" />
        </div>
      )}

      {error && (
        <Container size="xl">
          <Paper className={classes.paper}>
            <Text className={classes.errorText} size="lg">
              Authentication Error
            </Text>
            <Text className={classes.errorMessage}>
              Please contact{' '}
              <Anchor href="mailto:mvue@calfus.com" weight={700}>
                mvue@calfus.com
              </Anchor>{' '}
              for further assistance.
            </Text>
          </Paper>
        </Container>
      )}

      {!loading && !error && !authenticated && (
        <Container size="xl">
          <Paper className={classes.paper}>
            <Text className={classes.errorText} size="lg">
              Access Restricted
            </Text>
            <Text className={classes.errorMessage}>
              Please contact{' '}
              <Anchor href="mailto:mvue@calfus.com" weight={700}>
                mvue@calfus.com
              </Anchor>{' '}
              for further assistance.
            </Text>
          </Paper>
        </Container>
      )}
    </div>
  )
}

export default AuthenticationStatus
