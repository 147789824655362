import React, { useState, useEffect, useContext } from 'react'
import { Flex } from '@mantine/core'
import { RBACContext } from '../../context/CashRBAContext'
import { getAllEntities } from '../../apputils'
import ErrorImg from '../../assets/notFound.jpg'
import { EntitiesPermission } from '../../types'

function PermittedRoutes({ path = '', children = <></> }) {
  const initPermission = {
    HomePage: [],
    CashPage: [],
    InflowsPage: [],
    OutflowsPage: [],
    SimulationPage: [],
    CashFlowPage: [],
    KPIPage: [],
    AdminPage: [],
    POPage: [],
    Viewbankoverview: [],
    ViewBankpage: [],
  }
  const [permissions, setPermissions] =
    useState<EntitiesPermission>(initPermission)
  const [loading, setLoading] = useState(true)
  const { businessEntity, accessPermissions } = useContext(RBACContext)

  useEffect(() => {
    setLoading(true)
    const fetchedPermissions = getAllEntities(initPermission, accessPermissions)
    setPermissions(fetchedPermissions)
    setLoading(false)
  }, [businessEntity])

  const fieldMap: any = {
    admin: permissions.AdminPage,
    home: permissions.HomePage,
    cash: permissions.CashPage,
    inflows: permissions.InflowsPage,
    outflows: permissions.OutflowsPage,
    simulations: permissions.SimulationPage,
    cashflow: permissions.CashFlowPage,
    kpi: permissions.KPIPage,
    purchaseOrder: permissions.POPage,
    bankoverview: permissions.Viewbankoverview,
    bankregion: permissions.ViewBankpage,
  }

  const isValidRoute = () => {
    if (!fieldMap.hasOwnProperty(path)) {
      return false
    }
    const routePermissions = fieldMap[path]
    return routePermissions && routePermissions.length > 0
  }

  if (loading) {
    return null
  }

  if (!isValidRoute()) {
    return (
      <Flex align={'center'} justify={'center'}>
        <img
          src={ErrorImg}
          alt="PageNotFound"
          style={{ maxWidth: '100%', height: '550px' }}
        />
      </Flex>
    )
  }

  return children
}

export default PermittedRoutes
