import { Container, Grid } from '@mantine/core'
import { MvAssist } from '../components/Assist/MVAssist'

export const AssistPage = () => {
  return (
    <Container p={10}>
      <h2>Genie</h2>
      <Container my="md">
        <Grid>
          <Grid.Col span={12} h={'90vh'}>
            <MvAssist />
          </Grid.Col>
        </Grid>
      </Container>
    </Container>
  )
}
