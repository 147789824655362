import { FC } from 'react'
import { Navbar, MediaQuery } from '@mantine/core'
import { NavbarSimple } from '../../components/NavBar/NavBarSimple'

// Define props for the DashboardNavbar component
interface DashboardNavbarProps {
  className?: string
  opened: boolean
  handlers: { open: () => void; close: () => void; toggle: () => void }
}

export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  return (
    <>
      <NavbarSimple />
    </>
  )
}
