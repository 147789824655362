import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { KeycloakContextProvider } from './Keycloak/KeycloakProvider'
import { LicenseManager } from 'ag-grid-charts-enterprise'
import { AgCharts as AgChartsEnterprise } from 'ag-charts-enterprise'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059377}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Tradefull}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{tradefull-connect}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{tradefull-connect}_need_to_be_licensed___{tradefull-connect}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{2_May_2025}____[v3]_[0102]_MTc0NjE0MDQwMDAwMA==de24b8f0b65a79482ca24a84dd098b2a'
)

AgChartsEnterprise.setLicenseKey(
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059377}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Tradefull}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{tradefull-connect}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{tradefull-connect}_need_to_be_licensed___{tradefull-connect}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{2_May_2025}____[v3]_[0102]_MTc0NjE0MDQwMDAwMA==de24b8f0b65a79482ca24a84dd098b2a'
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter basename="/">
    <KeycloakContextProvider>
      <App />
    </KeycloakContextProvider>
  </BrowserRouter>
)

reportWebVitals()
