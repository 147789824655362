import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useRef,
} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import keycloakInstance from './keycloakInstance'
import {
  getToken,
  setAccessToken,
  clearTokens,
  refreshTokenIfNeeded,
  setRefreshToken,
  validateTokens,
  isTokenExpired,
} from './tokenService'
import trackCustomEvent from '../components/Tracking/customUmamiTracker'
import Keycloak from 'keycloak-js'
import { Loader, createStyles } from '@mantine/core'

const keycloakUrl = window._env_.REACT_APP_KEYCLOAK_URL
const keycloakRealm = window._env_.REACT_APP_KEYCLOAK_REALM
const keycloakClientId = window._env_.REACT_APP_KEYCLOAK_CLIENT_ID

interface KeycloakContextState {
  authenticated: boolean
  keycloakValue: any
  loading: boolean
  error: Error | null
  initialized: boolean
}

interface KeycloakContextProps extends KeycloakContextState {
  logout: () => void
}

const initialState: KeycloakContextState = {
  authenticated: false,
  keycloakValue: null,
  loading: true,
  error: null,
  initialized: false,
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background:
      localStorage.getItem('colorScheme') === 'light'
        ? 'rgb(248, 249, 250)'
        : 'rgb(36, 34, 57)',
  },
  loaderContainer: {
    textAlign: 'center',
  },
}))

const KeycloakContext = createContext<KeycloakContextProps>({
  ...initialState,
  logout: () => {},
})

const KeycloakContextProvider = (props: { children: ReactNode }) => {
  const { classes } = useStyles() // Call useStyles to get classes
  const [state, setState] = useState<KeycloakContextState>(initialState)
  const stateRef = useRef(state)
  const navigate = useNavigate()
  const location = useLocation()

  const updateState = (newState: Partial<KeycloakContextState>) => {
    setState((prevState) => {
      const updatedState = { ...prevState, ...newState }
      stateRef.current = updatedState
      return updatedState
    })
  }

  const initKeycloak = useCallback(async () => {
    clearTokens()
    const baseRedirectUri = window._env_.REACT_APP_KEYCLOAK_REDIRECT_URI

    if (!baseRedirectUri) {
      updateState({
        error: new Error(
          'Missing required Keycloak environment variable: REACT_APP_KEYCLOAK_REDIRECT_URI'
        ),
        loading: false,
        initialized: true,
      })
      return
    }

    const lastPath = sessionStorage.getItem('lastPath') || '/home'
    const redirectUri = `${baseRedirectUri}${lastPath}`

    try {
      const isAuthenticated = await keycloakInstance.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        redirectUri: redirectUri,
      })

      if (isAuthenticated) {
        const accessToken = keycloakInstance.token
        if (accessToken) {
          setAccessToken(accessToken)
          localStorage.setItem('isAuthenticated', 'true')
        }
        const refreshToken = keycloakInstance.refreshToken
        if (refreshToken) {
          setRefreshToken(refreshToken)
        }
        updateState({
          keycloakValue: keycloakInstance,
          authenticated: true,
          loading: false,
          initialized: true,
        })
        trackCustomEvent({ eventName: 'User Login' })
        navigate(lastPath)
      } else {
        keycloakInstance.login()
      }
    } catch (error) {
      console.error('Error initializing Keycloak:', error)
      updateState({
        error: error as Error,
        loading: false,
        initialized: true,
      })
    }
  }, [navigate])

  const logout = useCallback(async () => {
    try {
      const keycloakInstanceLogout = new Keycloak({
        url: keycloakUrl,
        realm: keycloakRealm,
        clientId: keycloakClientId,
      })
      await keycloakInstanceLogout.init({})
      await keycloakInstanceLogout.logout()

      await clearTokens()
      trackCustomEvent({ eventName: 'User Logout' })
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }, [])

  useEffect(() => {
    const checkAuthentication = async () => {
      const token = getToken()
      const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true'

      if (token && isAuthenticated) {
        if (isTokenExpired(token)) {
          clearTokens()
          initKeycloak()
        } else {
          const isValid = await validateTokens()
          if (isValid) {
            keycloakInstance.token = token
            updateState({
              authenticated: true,
              keycloakValue: keycloakInstance,
              loading: false,
              initialized: true,
            })
          } else {
            clearTokens()
            initKeycloak()
          }
        }
      } else {
        initKeycloak()
      }
    }

    checkAuthentication()
  }, [initKeycloak])

  useEffect(() => {
    if (stateRef.current.authenticated && stateRef.current.keycloakValue) {
      const refreshInterval = setInterval(
        async () => {
          try {
            const newToken = await refreshTokenIfNeeded()
            if (!newToken) {
              await logout()
            }
          } catch (error) {
            console.error('Failed to refresh token:', error)
            await logout()
          }
        },
        5 * 60 * 1000
      )

      return () => clearInterval(refreshInterval)
    }
  }, [state.authenticated, state.keycloakValue, logout, navigate])

  useEffect(() => {
    if (location.pathname !== '/') {
      sessionStorage.setItem('lastPath', location.pathname)
    }
  }, [location])

  return (
    <KeycloakContext.Provider value={{ ...state, logout }}>
      {state.loading ? (
        <div className={classes.container}>
          <div className={classes.loaderContainer}>
            <Loader size={65} color="blue" />
          </div>
        </div>
      ) : state.initialized ? (
        props.children
      ) : null}
    </KeycloakContext.Provider>
  )
}

export { KeycloakContextProvider, KeycloakContext }
