import {
  Button,
  createStyles,
  Group,
  Loader,
  Menu,
  ScrollArea,
  SegmentedControl,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { FC, useContext, useEffect, useState } from 'react'
import { getLocal, setLocal } from '../../apputils'
import { Entity, RBACContext } from '../../context/CashRBAContext'
import trackCustomEvent from '../Tracking/customUmamiTracker'

const MAX_SELECT = 3

const useStyles = createStyles((theme) => ({
  segmentedControl: {
    borderRadius: theme.radius.md,
  },
}))
interface LevelMenuProps {
  onChangeLevel: (value: Entity[]) => void
}

interface Options {
  value: string
  isSelected: boolean
  id: string
}

// Define the LevelMenu functional component
const LevelMenu: FC<LevelMenuProps> = ({ onChangeLevel }) => {
  const theme = useMantineTheme()
  const [options, setOptions] = useState<Options[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedLevel, setSelectedLevel] = useState('')
  const { businessEntity } = useContext(RBACContext)
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles()
  const { colorScheme } = useMantineColorScheme()
  const isDark = colorScheme === 'dark'

  const allOption = { value: 'All', isSelected: true, id: '-1' }

  const HandleEntityChange = (entity: Entity[]) => {
    onChangeLevel(entity)
    setLocal('selectedOU', entity.length > 1 ? 'All' : entity[0].entity)
  }

  const handleLevelChange = (value: string) => {
    if (value === 'All') {
      setSelectedLevel('All')
      HandleEntityChange(businessEntity)
    } else {
      setSelectedLevel(value)
      HandleEntityChange(businessEntity.filter((it) => it.entity === value))
    }
    trackCustomEvent({
      eventName: `Entity Tab Selection Changed`,
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const previousSelect = getLocal('selectedOU')
        setOptions(
          businessEntity.map((it: Entity) => ({
            value: it.entity,
            isSelected: it.entity === previousSelect,
            id: it.id,
          }))
        )
        if (businessEntity.length > 1) {
          if (previousSelect) handleLevelChange(previousSelect)
          else {
            setSelectedLevel('All')
            HandleEntityChange(businessEntity)
          }
          if (businessEntity.length <= MAX_SELECT) {
            setOptions(
              businessEntity.map((it: Entity) => ({
                value: it.entity,
                isSelected: true,
                id: it.id,
              }))
            )
          }
        } else if (businessEntity.length === 1) {
          setSelectedLevel(businessEntity[0].entity)
          HandleEntityChange(businessEntity)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const levelOptions: Options[] =
    options.length > 1 ? [allOption, ...options] : options

  const updateSelection = (idx: number) => {
    const c = options.reduce((a, cur) => (a += cur.isSelected ? 1 : 0), 0)
    if (!options[idx].isSelected && c >= MAX_SELECT) return
    if (options[idx].value === selectedLevel) return
    options[idx].isSelected = !options[idx].isSelected
    setOptions([...options])
    trackCustomEvent({
      eventName: `Entity Tab Selection Updated`,
    })
  }

  const cnt = options.reduce((a, cur) => (a += !cur.isSelected ? 1 : 0), 0)
  return (
    <Group>
      {loading ? (
        <Loader />
      ) : (
        <>
          <SegmentedControl
            left="0.9rem"
            data={levelOptions
              .filter((it) => it.isSelected)
              .map((it) => it.value)}
            value={selectedLevel}
            onChange={handleLevelChange}
            size="sm"
            className={classes.segmentedControl}
            bg={isDark ? theme.colors.dark[6] : ''}
            fullWidth
          />
          {options.length > MAX_SELECT && (
            <Menu opened={opened} onChange={setOpened} trigger="click">
              <Menu.Target>
                <Button onChange={() => setOpened((o) => !o)}>
                  {cnt !== 0 ? `${cnt} More` : 'Update'}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <ScrollArea h={Math.min(250, 36 * options.length)}>
                  {options.map((it: Options, i: number) => (
                    <Menu.Item
                      closeMenuOnClick={false}
                      onClick={() => updateSelection(i)}
                      key={it.value}
                      rightSection={
                        it.isSelected ? <IconCheck size={14} /> : <></>
                      }
                    >
                      {it.value}
                    </Menu.Item>
                  ))}
                </ScrollArea>
              </Menu.Dropdown>
            </Menu>
          )}
        </>
      )}
    </Group>
  )
}

export default LevelMenu
