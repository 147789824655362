import axios from 'axios'
import {
  getToken,
  refreshTokenIfNeeded,
  handleApiError,
} from './Keycloak/tokenService'
import { EntitiesPermission } from './types'
import { Entity } from './context/CashRBAContext'
import trackCustomEvent from './components/Tracking/customUmamiTracker'

const API_BASE_URL = window._env_.REACT_APP_MVUE_API_BASEURL

const createAuthenticatedAxiosInstance = async (customBaseURL?: string) => {
  await refreshTokenIfNeeded()
  const token = getToken()
  if (!token) {
    throw new Error('No token available after refresh attempt')
  }
  return axios.create({
    baseURL: customBaseURL || API_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export async function authorizedFetch(
  endpoint: string,
  queryParams?: Record<string, string | Date | number | undefined>,
  customBaseURL?: string
): Promise<any> {
  const baseURL = customBaseURL || API_BASE_URL
  let url = `${baseURL}${endpoint}`
  if (queryParams) {
    const params = new URLSearchParams()
    for (const [key, value] of Object.entries(queryParams)) {
      if (value !== undefined) {
        params.append(key, value.toString())
      }
    }
    url += `?${params.toString()}`
  }

  try {
    const axiosInstance = await createAuthenticatedAxiosInstance(customBaseURL)
    const eventName = `API GET - ${endpoint}`
    trackCustomEvent({
      eventName: eventName,
      method: 'GET',
      endpoint: endpoint,
      fullURL: url,
    })

    const response = await axiosInstance.get(url)

    return response.data
  } catch (error) {
    return handleApiError(error)
  }
}

export async function authorizedPost(
  endpoint: string,
  data: any,
  headers: any = {},
  customBaseURL?: string
): Promise<any> {
  const baseURL = customBaseURL || API_BASE_URL
  const url = `${baseURL}${endpoint}`

  try {
    const axiosInstance = await createAuthenticatedAxiosInstance(customBaseURL)
    const isFormData = data instanceof FormData

    trackCustomEvent({
      eventName: `API POST - ${endpoint}`,
      method: 'POST',
      endpoint: endpoint,
      fullURL: url,
    })

    const response = await axiosInstance.post(endpoint, data, {
      headers: {
        ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
        ...headers,
      },
    })

    return response
  } catch (error) {
    return handleApiError(error)
  }
}

export async function authorizedPatch(
  endpoint: string,
  data: any,
  headers: any = {},
  customBaseURL?: string
): Promise<any> {
  const baseURL = customBaseURL || API_BASE_URL
  const url = `${baseURL}${endpoint}`
  try {
    const axiosInstance = await createAuthenticatedAxiosInstance(customBaseURL)
    const isFormData = data instanceof FormData
    trackCustomEvent({
      eventName: `API PATCH - ${endpoint}`,
      method: 'PATCH',
      endpoint: endpoint,
      fullURL: url,
    })

    const response = await axiosInstance.patch(endpoint, data, {
      headers: {
        ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
        ...headers,
      },
    })

    return response
  } catch (error) {
    return handleApiError(error)
  }
}

export async function authorizedPut(
  endpoint: string,
  data: any,
  headers: any = {},
  customBaseURL?: string
): Promise<any> {
  const baseURL = customBaseURL || API_BASE_URL
  const url = `${baseURL}${endpoint}`
  try {
    const axiosInstance = await createAuthenticatedAxiosInstance(customBaseURL)
    const isFormData = data instanceof FormData
    trackCustomEvent({
      eventName: `API PUT - ${endpoint}`,
      method: 'PUT',
      endpoint: endpoint,
      fullURL: url,
    })
    const response = await axiosInstance.put(endpoint, data, {
      headers: {
        ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
        ...headers,
      },
    })

    return response
  } catch (error) {
    return handleApiError(error)
  }
}

export async function authorizedDelete(
  endpoint: string,
  data: any = {},
  customBaseURL?: string
): Promise<any> {
  const baseURL = customBaseURL || API_BASE_URL
  const url = `${baseURL}${endpoint}`
  try {
    const axiosInstance = await createAuthenticatedAxiosInstance(customBaseURL)
    trackCustomEvent({
      eventName: `API DELETE - ${endpoint}`,
      method: 'DELETE',
      endpoint: endpoint,
      fullURL: url,
    })
    const response = await axiosInstance.delete(endpoint, { data })
    return response
  } catch (error) {
    return handleApiError(error)
  }
}

export function convertToUTC(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('Invalid date')
  }

  const utcDate = date.toISOString().split('T')[0]
  return utcDate
}

export function newUTC(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('Invalid date')
  }

  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const utcDate = `${year}-${String(month).padStart(2, '0')}-${String(
    day
  ).padStart(2, '0')}`

  return utcDate
}

export const decodeJWT = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

export const getTabLevelEntities = (
  selectedEntity: Entity[],
  accessPermissions: any
) => {
  const tabLevelPermission: EntitiesPermission = {
    CashCards: [],
    CashPredictionChart: [],
    ActualReceipts: [],
    ActualDisbursements: [],
    InflowsCSVDownload: [],
    InflowsPDFDownload: [],
    OutflowsPDFDownload: [],
    OutflowsCSVDownload: [],
    SimulationCards: [],
    SimulationGraph: [],
  }
  const persmissions = Object.keys(tabLevelPermission)
  const selects = new Set<number>(selectedEntity.map((it) => parseInt(it.id)))
  if (accessPermissions) {
    const selectedEntityPerms =
      accessPermissions?.filter((it: any) => selects.has(it.id)) ?? []
    selectedEntityPerms?.forEach((accessLevel: any) => {
      persmissions.forEach((permission: string) => {
        if (accessLevel?.permissions[permission])
          tabLevelPermission[permission].push({
            id: accessLevel.id,
            name: accessLevel.Entities,
          })
      })
    })
    return tabLevelPermission
  }
  return getAllEntities(tabLevelPermission, accessPermissions)
}

export const getAllEntities = (
  entitiesPermission: EntitiesPermission,
  accessPermissions: any
) => {
  const persmissions = Object.keys(entitiesPermission)
  // clear init
  persmissions.forEach((persmission) => (entitiesPermission[persmission] = []))
  if (accessPermissions)
    accessPermissions?.forEach((accessLevel: any) => {
      persmissions.forEach((permission: string) => {
        if (accessLevel?.permissions[permission])
          entitiesPermission[permission].push({
            id: accessLevel.id,
            name: accessLevel.Entities,
          })
      })
    })
  return entitiesPermission
}

export function setLocal(key: string, value: string) {
  localStorage.setItem(key, value)
}

export function getLocal(key: string): string | null {
  return localStorage.getItem(key)
}

export const dateComparator = (filterDate: Date, cellValue: string): number => {
  if (!cellValue) return -1

  const cellDate = new Date(cellValue + 'T00:00:00Z')

  const normalizedFilterDate = new Date(
    Date.UTC(
      filterDate.getFullYear(),
      filterDate.getMonth(),
      filterDate.getDate()
    )
  )

  if (normalizedFilterDate.getTime() === cellDate.getTime()) {
    return 0
  } else if (cellDate < normalizedFilterDate) {
    return -1
  } else if (cellDate > normalizedFilterDate) {
    return 1
  }

  return 0
}
