import {
  Anchor,
  Button,
  Container,
  Divider,
  Group,
  Modal,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import React, { useState } from 'react'
import { authorizedPost } from '../../apputils'
import './MVueLayout.css'

// Define props for the DashboardFooter component
interface DashboardFooterProps {
  className?: string
}

// Create the DashboardFooter component as a functional component
export const DashboardFooter: React.FC<DashboardFooterProps> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [feedbackText, setFeedbackText] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mode, setMode] = useState<'Help' | 'Feedback'>('Feedback')
  const [helpText, setHelpText] = useState('')

  const support = () => {
    setMode('Help')
    setIsModalOpen(true)
  }

  const openFeedbackModal = () => {
    setMode('Feedback')
    setIsModalOpen(true)
  }

  const closeFeedbackModal = () => {
    // Close the feedback modal
    setIsModalOpen(false)
    setFirstName('')
    setLastName('')
    setEmail('')
    setFeedbackText('')
    setHelpText('')
  }

  const handleModalSubmit = async () => {
    try {
      let text = ''
      if (mode === 'Help') {
        text = `First Name: ${firstName}\nLast Name: ${lastName}\nEmail: ${email}\nHelp: ${feedbackText}`
      } else {
        text = `First Name: ${firstName}\nLast Name: ${lastName}\nEmail: ${email}\nFeedback: ${feedbackText}`
      }

      // Send a POST request to the /send-email endpoint
      const response = await authorizedPost(`/send-email`, {
        text: text,
        subject: mode === 'Help' ? 'Help' : 'Feedback',
      })

      // Handle response if needed
      console.log('Feedback submitted:', text)
      console.log('Response:', response.data)

      // Close the modal
      closeFeedbackModal()
    } catch (error) {
      // Handle error
      console.error('Error submitting feedback:', error)
      // Optionally, you can provide feedback to the user about the error
    }
  }

  return (
    <Container className={props.className}>
      {/* Create a Group to hold footer links and text */}
      <Group className={props.className}>
        {/* Footer link: Help */}
        <Anchor href="#" color="dimmed" underline={false}>
          Help
        </Anchor>
        {/* Divider to separate links */}
        <Divider orientation="vertical" />
        {/* Footer link: Feedback */}
        <Anchor href="#" color="dimmed" underline={false}>
          Feedback
        </Anchor>
        {/* Divider to separate links */}
        <Divider orientation="vertical" />
        {/* Footer link: Privacy */}
        <Anchor
          href="/privacy"
          target="_blank" // open in a new tab
          color="dimmed"
          underline={false}
        >
          Privacy
        </Anchor>
        {/* Divider to separate links */}
        <Divider orientation="vertical" />
        {/* Copyright text */}
        <Text color="dimmed">© 2024 Calfus, All Rights Reserved</Text>

        {/* Feedback Modal */}
        <Modal
          opened={isModalOpen}
          onClose={closeFeedbackModal}
          title={
            mode === 'Help' ? 'Thank you for reaching out! ' : 'Thank You!'
          }
          centered
          size="lg"
          className="feedback"
          radius="md"
        >
          <Text color="dimmed">
            {mode === 'Help'
              ? "Whether you have questions, need help, or encounter any issues - we're here to serve. Your input helps us provide you with better support and improve our services."
              : 'Your feedback is important to us. MVue team will contact you for next steps.'}
          </Text>
          {/* Modal content */}
          <TextInput
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            mb="1rem"
            mt="1rem"
          />
          <TextInput
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            mb="1rem"
          />
          <TextInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            mb="1rem"
          />
          <Textarea
            value={feedbackText}
            onChange={(event) => setFeedbackText(event.currentTarget.value)}
            placeholder={
              mode === 'Help'
                ? "Please tell us in brief about what you're seeking assistance with"
                : 'Your feedback goes here...'
            }
            mb="1rem"
          />
          <Group align="right" spacing="2rem">
            <Button color="blue" onClick={handleModalSubmit} mt="lg" w="20%">
              Submit
            </Button>
            <Button mr="md" onClick={closeFeedbackModal} mt="lg" w="20%">
              Cancel
            </Button>
          </Group>
        </Modal>
      </Group>
    </Container>
  )
}
