import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts'

interface MVBarChartProps {
  data: any[]
}
export const MVBarChart = ({ data }: MVBarChartProps) => {
  const allKeys: any[] = []

  // console.log(`data: ${data}`);
  // console.log(`data length: ${data.length}`);

  data.map((item) => {
    const objectKeys = Object.keys(item)
    objectKeys.map((key) => {
      if (!allKeys.includes(key)) {
        console.log(`adding keys: ${key}`)
        allKeys.push(key)
      }
    })
  })

  const dataKeys = allKeys.filter((item) => item !== 'name')

  return (
    <>
      <BarChart width={600} height={300} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {dataKeys.map((key, i) => (
          <Bar dataKey={key} key={i} fill="#70e2c0" />
        ))}
        {/* <Bar
          dataKey="Number of Overdue Invoices"
          // fill="#8884d8"
          activeBar={<Rectangle fill="pink" stroke="blue" />}
        /> */}
      </BarChart>
    </>
  )
}
