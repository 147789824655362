import { React } from 'react'
import { Image } from '@mantine/core'

const getRoles = () => {
  const findUserDetails = localStorage.getItem('userDetails')
  const parsedData = findUserDetails && JSON.parse(findUserDetails)
  return parsedData ? parsedData.results.user_role : []
}

const isAdmin = () => {
  return getRoles().length > 0 && getRoles().some((el) => el === 'Admin')
    ? true
    : false
}

const getImage = (data) => {
  if (data.user_image !== null && data.user_image !== '') {
    return (
      <Image
        src={data.user_image}
        alt="AA"
        width={35}
        height={35}
        radius={50}
      />
    )
  } else {
    const firstInitial = data.firstName.charAt(0).toUpperCase()
    const lastInitial = data.lastName.charAt(0).toUpperCase()

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '35px',
          height: '35px',
          background: '#fff',
          borderRadius: '50%',
          color: '#000',
        }}
      >
        {firstInitial}
        {lastInitial}
      </div>
    )
  }
}

export { getRoles, isAdmin, getImage }
