import React, { useState } from 'react'
import {
  Group,
  Box,
  Collapse,
  Text,
  UnstyledButton,
  createStyles,
  rem,
} from '@mantine/core'
import { IconChevronRight, IconHelp } from '@tabler/icons-react'

import { useNavigate, useLocation } from 'react-router-dom'
import { active } from 'd3'

// Define CSS styles using Mantine's createStyles function
const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 400,
    display: 'block',
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.white
        : theme.fn.primaryColor(),
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.radius.xs,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.light[1],
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.white
          : theme.fn.primaryColor(),
      borderRadius: theme.radius.sm,
    },
  },
  link: {
    width: '85%',
    fontWeight: 400,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(30),
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.fn.primaryColor(),
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
    }`,
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,

    '&:active': {
      backgroundColor: theme.colors.light[1],
    },

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.light[1],
      color:
        theme.colorScheme === 'dark' ? theme.white : theme.fn.primaryColor(),
    },
  },
  active: {
    // backgroundColor: theme.fn.primaryColor(),
    // color: theme.colors.dark[0],
  },
  chevron: {
    transition: 'transform 200ms ease',
  },
}))

// Define the props for the LinksGroup component
interface LinksGroupProps {
  icon: React.FC<any>
  label: string
  link: string
  initiallyOpened?: boolean
  links?: { label: string; link: string; enabled?: boolean }[]
  activeLink?: string
  activeClass: any
  onClick?: any
  color?: any
  enabled?: boolean
}

export function LinksGroup({
  icon: Icon,
  label,
  link,
  initiallyOpened,
  links,
  activeLink,
  activeClass,
  onClick,
  color,
  enabled,
}: LinksGroupProps) {
  const navigate = useNavigate()
  const { classes, theme, cx } = useStyles()
  const hasLinks = Array.isArray(links)
  const [opened, setOpened] = useState(initiallyOpened || false)

  const items = (hasLinks ? links : []).map((link) => (
    <>
      {link.enabled && (
        <Text<'a'>
          component="a"
          className={cx(classes.link, {
            [classes.active]: link.link === activeLink,
          })}
          href={link.link}
          key={link.label}
          onClick={(event) => {
            event.preventDefault()
            navigate(link.link)
          }}
        >
          {link.label}
        </Text>
      )}
    </>
  ))

  return (
    <>
      {enabled && (
        <>
          <UnstyledButton
            onClick={() => {
              setOpened((o) => !o)
              navigate(link)
              onClick && onClick()
            }}
            className={cx(classes.control, {
              [classes.active]: link === activeLink || activeClass,
              parentActiveClass: activeClass,
            })}
          >
            <Group position="apart" spacing={0}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon color={color} size="1.3rem" variant="filled" />
                <Box ml="md">{label}</Box>
              </Box>
              {hasLinks && (
                <IconChevronRight
                  className={classes.chevron}
                  size="1rem"
                  stroke={1.5}
                  style={{
                    transform: opened
                      ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)`
                      : 'none',
                  }}
                />
              )}
            </Group>
          </UnstyledButton>
          {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
        </>
      )}
    </>
  )
}

// Component that represents a group of links in the navbar
export function NavbarLinksGroup() {
  const location = useLocation()
  return (
    <Box
      sx={(theme) => ({
        minHeight: rem(220),
        padding: theme.spacing.md,
        backgroundColor:
          theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      })}
    >
      <LinksGroup
        label="Support"
        link="/support"
        activeLink={location.pathname}
        icon={IconHelp}
        activeClass={false}
      />
    </Box>
  )
}
