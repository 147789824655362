// CustomHomeIcon.tsx
import React from 'react'
import './styles.css'
import { Image } from '@mantine/core'

interface CustomIconProps {
  src: string
  alt: string
}

const CustomIcon: React.FC<CustomIconProps> = ({ src, alt }) => {
  return <Image src={`${window._env_.REACT_APP_IMAGES_URL}/${src}`} alt={alt} />
}

export default CustomIcon
