import {
  Card,
  createStyles,
  Flex,
  Grid,
  ScrollArea,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  IconBrain,
  IconChartBar,
  IconRotate2,
  IconTable,
  IconThumbDown,
  IconThumbUp,
  IconUser,
} from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { LineWave } from 'react-loader-spinner'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { v4 as uuidv4 } from 'uuid'
import { authorizedPost } from '../../apputils'
import './MVAssist.css'
import { MVBarChart } from './MVBarChart'

const useStyles = createStyles((theme) => ({
  tile: {
    minHeight: '320px',
    maxHeight: '320px',
    // overflow: "auto",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
  },
  cardstyle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.5rem',
  },
  gridstyle: {
    flexDirection: 'row',
  },
  gridflex: {
    flex: 1,
  },
  container: {
    height: '100%',
    overflowY: 'auto',
    borderRadius: '0.25rem',
    // padding: "0.5rem",
    paddingBottom: '0',
  },

  inputfield: {
    flex: 1,
    borderRadius: '0.5rem',
    border: 'none',
    paddingBottom: '0',
  },

  fom: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    marginBottom: '1rem',
    paddingLeft: '0.5rem',
  },
  promp: {
    textAlign: 'start',
    marginLeft: '0.8rem',
  },
  resetbutton: {
    position: 'relative',
    height: '100%',
  },
  reset: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}))

export const MvAssist = () => {
  const containerProps = {
    // bg: "gray",
    // h: "27vh",
    // mt: "md",
    radius: 'md',
  }

  type Message = {
    role: string
    message: QuestionResponse
  }

  type AnswerMessage = {
    summary: string
    data: string
    chart_type: string
    chart_data: any[]
  }

  type AnswerDetail = {
    id: number
    message: AnswerMessage
  }

  type QuestionResponse = {
    question: string
    query: string
    answer: AnswerDetail
  }

  const form = useForm({
    initialValues: { question: '' },
  })

  const [answer, setAnswer] = useState<Message[]>([])
  const [processing, setProcessing] = useState<boolean>(false)
  const [openDataIndex, setOpenDataIndex] = useState<number[]>([])
  const [openBarChartIndex, setOpenBarChartIndex] = useState<number[]>([])
  const [sessionID, setSessionID] = useState<string>(uuidv4())
  const [isThumbUpHovered, setIsThumbUpHovered] = useState<boolean>(false)
  const [isThumbDownHovered, setIsThumbDownHovered] = useState<boolean>(false)
  const [isDataTableHovered, setIsDataTableHovered] = useState<boolean>(false)
  const [isBarChartHovered, setIsBarChartHovered] = useState<boolean>(false)
  const feedbackMode: boolean =
    window._env_.REACT_APP_ASSIST_FEEDBACK_MODE === 'true' ? true : false
  const viewport = React.useRef<HTMLDivElement>(null)
  const theme = useMantineTheme()
  const { classes } = useStyles()
  const GENIE_BASE_URL = window._env_.REACT_APP_GENIE_API_BASEURL
  useEffect(() => {
    if (viewport.current) {
      viewport.current.scrollTo({
        top: viewport.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [answer.length])

  const inputQuestionRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        inputQuestionRef.current?.focus()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleSubmit = async (values: { question: string }) => {
    // console.log(`ask clicked question is ${values.question}`);
    setProcessing(true)
    // console.log(`processing is ${processing}`);
    setAnswer((prevAnswer) => [
      ...prevAnswer,
      {
        role: 'user',
        message: {
          question: values.question,
          query: '',
          answer: {
            id: 0,
            message: {
              summary: '',
              data: '',
              chart_data: [],
              chart_type: '',
            },
          },
        },
      },
    ])
    form.reset()

    const resp = await authorizedPost(
      '/ask',
      {
        id: sessionID,
        question: values.question,
        feedback_mode: feedbackMode,
        language: 'en',
      },
      {},
      GENIE_BASE_URL
    )
      .then((response) => {
        const parsed_response: QuestionResponse = response.data

        setAnswer((prevAnswer) => [
          ...prevAnswer,
          { role: 'ai', message: parsed_response },
        ])

        setProcessing(false)
      })
      .catch((error) => {
        console.log(error)

        setAnswer((prevAnswer) => [
          ...prevAnswer,
          {
            role: 'ai',
            message: {
              question: values.question,
              query: '',
              answer: {
                id: 0,
                message: {
                  summary: "I am sorry I don't have an answer",
                  data: '',
                  chart_data: [],
                  chart_type: '',
                },
              },
            },
          },
        ])
        setProcessing(false)
      })
    // if (viewport.current) {

    //   viewport.current.scrollTo({
    //     top: viewport.current.scrollHeight,
    //     behavior: "smooth",
    //   });
    // }
  }

  const handleVote = (id: number, up: boolean, index: number): void => {
    authorizedPost(`/vote?id=${id}&vote=${up}`, null, {}, GENIE_BASE_URL)
      .then((response) => {
        const oldAnswers = [...answer]
        console.log(oldAnswers[index])
      })
      .catch((error) => {
        console.error('Error voting:', error)
      })
  }

  const startNewChat = () => {
    // console.log(`start new chat`);
    setAnswer([])
    setSessionID(uuidv4())
  }

  const handleDataVisibility = (index: number): void => {
    // console.log(`data visibility clicked ${index}`);
    if (openDataIndex.includes(index)) {
      setOpenDataIndex(openDataIndex.filter((i) => i !== index))
    } else {
      setOpenDataIndex([...openDataIndex, index])
    }
  }

  const handleBarChartVisibility = (index: number) => {
    console.log('handling bar chart')
    if (openBarChartIndex.includes(index)) {
      setOpenBarChartIndex(openBarChartIndex.filter((i) => i !== index))
    } else {
      setOpenBarChartIndex([...openBarChartIndex, index])
    }
  }

  return (
    <>
      <Card radius="md" withBorder mt="0.2rem" h={'100%'}>
        <Flex direction={'column'} justify={'space-between'} h={'100%'}>
          <ScrollArea
            type="always"
            {...containerProps}
            viewportRef={viewport}
            style={{ flex: 1 }}
          >
            {answer.map((msg, index) => (
              <Grid key={index} p={10}>
                <Grid.Col span={1}>
                  <Flex justify={'center'}>
                    {msg.role === 'ai' ? (
                      <IconBrain color="#d08c60" size={'20'}></IconBrain>
                    ) : (
                      <IconUser color="#f1dca7" size={'20'}></IconUser>
                    )}
                  </Flex>
                </Grid.Col>
                {msg.role === 'user' ? (
                  <Grid.Col span={9}>{msg.message.question}</Grid.Col>
                ) : (
                  <>
                    <Grid.Col span={10}>
                      {msg.message.query ? (
                        <div>
                          Query - {msg.message.query} <br />
                        </div>
                      ) : (
                        ''
                      )}
                      <div>{msg.message.answer.message.summary}</div>
                      {msg.message.answer.message.data ? (
                        <div>
                          <Flex justify={'start'} m={'md'}>
                            <Tooltip
                              label="Data Table"
                              color="rgba(0,0,0, 0.7)"
                              position={'bottom'}
                            >
                              <div
                                style={{
                                  display: 'inline-flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%',
                                  transition: 'background-color 0.3s ease',
                                  backgroundColor: isDataTableHovered
                                    ? 'rgba(221, 172, 174, 0.2)'
                                    : 'transparent',
                                  width: '30px',
                                  height: '30px',
                                }}
                                onMouseEnter={() => setIsDataTableHovered(true)}
                                onMouseLeave={() =>
                                  setIsDataTableHovered(false)
                                }
                              >
                                <IconTable
                                  size={'16'}
                                  color={
                                    isDataTableHovered ? 'lightgray' : 'gray'
                                  }
                                  onClick={() => handleDataVisibility(index)}
                                />
                              </div>
                            </Tooltip>
                            {msg.message.answer.message.chart_type !== 'none' &&
                            msg.message.answer.message.chart_data.length !==
                              0 ? (
                              <Tooltip
                                label="Bar Chart"
                                color="rgba(0,0,0, 0.7)"
                                position={'bottom'}
                              >
                                <div
                                  style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '50%',
                                    transition: 'background-color 0.3s ease',
                                    backgroundColor: isBarChartHovered
                                      ? 'rgba(221, 172, 174, 0.2)'
                                      : 'transparent',
                                    width: '30px',
                                    height: '30px',
                                  }}
                                  onMouseEnter={() =>
                                    setIsBarChartHovered(true)
                                  }
                                  onMouseLeave={() =>
                                    setIsBarChartHovered(false)
                                  }
                                >
                                  <IconChartBar
                                    size={'16'}
                                    color={'gray'}
                                    onClick={() =>
                                      handleBarChartVisibility(index)
                                    }
                                  />
                                </div>
                              </Tooltip>
                            ) : (
                              ''
                            )}
                            {feedbackMode ? (
                              <Flex
                                style={{ flexGrow: 1 }}
                                direction={'row'}
                                justify={'end'}
                                gap={'sm'}
                                // p={10}
                              >
                                <Tooltip
                                  label="Right Answer"
                                  color="rgba(0,0,0, 0.7)"
                                  position={'bottom'}
                                >
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '50%',
                                      transition: 'background-color 0.3s ease',
                                      backgroundColor: isThumbUpHovered
                                        ? 'rgba(221, 172, 174, 0.2)'
                                        : 'transparent',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    onMouseEnter={() =>
                                      setIsThumbUpHovered(true)
                                    }
                                    onMouseLeave={() =>
                                      setIsThumbUpHovered(false)
                                    }
                                  >
                                    <IconThumbUp
                                      // color="#48a9a6"
                                      color={
                                        isThumbUpHovered ? '#6CD09D' : '#48a9a6'
                                      }
                                      size={'16'}
                                      // onMouseEnter={() =>
                                      //   setIsThumbUpHovered(true)
                                      // }
                                      // onMouseLeave={() =>
                                      //   setIsThumbUpHovered(false)
                                      // }
                                      onClick={() =>
                                        handleVote(
                                          msg.message.answer.id,
                                          true,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  label="That was not right"
                                  color="rgba(0,0,0, 0.7)"
                                  position={'bottom'}
                                >
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderRadius: '50%',
                                      transition: 'background-color 0.3s ease',
                                      backgroundColor: isThumbDownHovered
                                        ? 'rgba(221, 172, 174, 0.2)'
                                        : 'transparent',
                                      width: '30px',
                                      height: '30px',
                                    }}
                                    onMouseEnter={() =>
                                      setIsThumbDownHovered(true)
                                    }
                                    onMouseLeave={() =>
                                      setIsThumbDownHovered(false)
                                    }
                                  >
                                    <IconThumbDown
                                      // color="#ddacae"
                                      color={
                                        isThumbDownHovered
                                          ? '#ddacae'
                                          : '#c1666b'
                                      }
                                      size={'16'}
                                      // onMouseEnter={() =>
                                      //   setIsThumbDownHovered(true)
                                      // }
                                      // onMouseLeave={() =>
                                      //   setIsThumbDownHovered(false)
                                      // }
                                      onClick={() =>
                                        handleVote(
                                          msg.message.answer.id,
                                          false,
                                          index
                                        )
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </Flex>
                            ) : (
                              ''
                            )}
                          </Flex>
                        </div>
                      ) : (
                        ''
                      )}
                      {openDataIndex.includes(index) ? (
                        <div className="custom-table">
                          <Markdown remarkPlugins={[[remarkGfm]]}>
                            {msg.message.answer.message.data}
                          </Markdown>
                        </div>
                      ) : (
                        ''
                      )}
                      {openBarChartIndex.includes(index) ? (
                        <>
                          <MVBarChart
                            data={
                              msg.message.answer.message.chart_data //JSON.parse(msg.message.answer.message.chart_data),
                            }
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </Grid.Col>
                  </>
                )}
              </Grid>
            ))}
          </ScrollArea>

          <form onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              // mt={"md"}
              className="muassist-input"
              placeholder="Type your question here - Ctrl/⌘ + K to focus"
              {...form.getInputProps('question')}
              rightSection={
                processing ? (
                  <>
                    <LineWave
                      color="orange"
                      visible={true}
                      height={40}
                      width={150}
                    />
                    <IconRotate2
                      size={'16'}
                      onClick={startNewChat}
                    ></IconRotate2>
                  </>
                ) : (
                  <IconRotate2 size={'16'} onClick={startNewChat}></IconRotate2>
                )
              }
              ref={inputQuestionRef}
            />
          </form>
        </Flex>
      </Card>
    </>
  )
}
