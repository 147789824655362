import {
  ActionIcon,
  createStyles,
  Flex,
  Grid,
  Header,
  MediaQuery,
  Modal,
  rem,
  Select,
  Text,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core'

import { FC, useContext, useEffect, useState } from 'react'

import { IconBulb, IconBulbOff, IconFilter } from '@tabler/icons-react'
import { useLocation } from 'react-router-dom'
import { authorizedFetch, decodeJWT } from '../../apputils'
import LevelMenu from '../../components/common/EntityMenu'
import { FilterMenu } from '../../components/common/FilterMenu'
import { CashContext, RBACContext } from '../../context/CashRBAContext'
import { getImage } from '../../utils'
import './MVueLayout.css'

// Define props for the DashboardHeader component
interface DashboardHeaderProps {
  className?: string
  opened: boolean
  handlers: { open: () => void; close: () => void; toggle: () => void }
}

const useStyles = createStyles((theme) => ({
  alert: {
    maxHeight: '14rem',
    overflowY: 'auto',
  },
  userIcon: {
    width: rem(33),
    height: rem(33),
  },
  toolTipText: {
    marginBottom: 0,
    marginTop: 0,
    fontSize: '12px',
  },
  profileImg: {
    width: rem(35),
    height: rem(35),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bankPlaceholder: {
    'mantine-MultiSelect-searchInput ::placeholder': {
      color: theme.colors.red[5],
    },
  },
}))

export const DashboardHeader: FC<DashboardHeaderProps> = (props) => {
  // State to store the last update date and time
  const [, setLastUpdate] = useState<string>('')
  // Access the Mantine theme
  const theme = useMantineTheme()
  // Access the current location using React Router
  const location = useLocation()

  // handle the datepicker modal in lower resolutions
  const [enabledModal, setEnabledModal] = useState(false)
  const [fxCurrencyData, setFxCurrencyData] = useState<any>(null)

  // Access context for managing cash data
  const { setFromValue, setToValue, setDataIndex } = useContext(CashContext)
  const { setSelectedLevel } = useContext(RBACContext)

  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const isDark = colorScheme === 'dark'

  useEffect(() => {
    // Function to get the current date and time
    const getCurrentDate = () => {
      const now = new Date(
        Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate()
        )
      )

      const formattedDate = now.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })

      return formattedDate
    }

    // Set the last update date and time when the component mounts
    setLastUpdate(getCurrentDate())
  }, [])

  // Function to handle date change in filters
  const handleChangeDate = (
    period: string,
    from: any,
    to: any,
    dataInd: number
  ) => {
    setFromValue(from)
    setToValue(to)
    setDataIndex(dataInd)
  }

  const { classes, cx } = useStyles()
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email_address: '',
    user_status: '',
    user_image: '',
    u_id: '',
  })
  useEffect(() => {
    const storedUserDetails: any = localStorage.getItem('accessToken')
    const data = decodeJWT(storedUserDetails)

    if (data) {
      const { given_name, family_name, email, userImage } = data

      setUserDetails({
        firstName: given_name,
        lastName: family_name,
        email_address: email,
        user_status: '',
        user_image: userImage || '',
        u_id: '',
      })
    }
  }, [])

  useEffect(() => {
    // Function to fetch data from API
    const fetchFxCurrencyData = async () => {
      try {
        const response = await authorizedFetch(`/fx_currency`)
        setFxCurrencyData(response)
      } catch (error) {
        console.error('Error fetching FX currency data:', error)
      }
    }

    // Call the function to fetch data when the component mounts
    fetchFxCurrencyData()
  }, [])

  const firstName = userDetails.firstName
  const lastName = userDetails.lastName

  return (
    <>
      <Modal
        size="lg"
        opened={enabledModal}
        onClose={() => setEnabledModal(false)}
        title="Select the dates"
        style={{ height: enabledModal ? '500px' : 'auto' }}
        className="custom-modal-filter"
      >
        <Grid>
          <Grid.Col span={12}>
            <LevelMenu onChangeLevel={setSelectedLevel} />
          </Grid.Col>
          <FilterMenu onChangeFilter={handleChangeDate} isModal={true} />
        </Grid>
      </Modal>
      <Header
        height="auto"
        bg={isDark ? theme.colors.dark[8] : theme.colors.light[1]}
      >
        <Grid className="w-100" p="md" pb="0">
          <Grid.Col span={12}>
            <Flex justify="right" align="center">
              {location.pathname !== '/cashflow' && (
                <>
                  <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                    <Text
                      size="90%"
                      color={theme.colors.gray[6]}
                      style={{ marginRight: rem(10) }}
                    >
                      {' '}
                      All Amounts in
                    </Text>
                  </MediaQuery>
                  {fxCurrencyData && (
                    <Select
                      data={fxCurrencyData.map(
                        (currency: any) => currency['Currency Code']
                      )}
                      style={{
                        marginRight: rem(10),
                        maxWidth: 85,
                      }}
                      defaultValue={fxCurrencyData[0]['Currency Code']}
                    />
                  )}
                </>
              )}

              <ActionIcon
                variant=""
                radius={18}
                size={38}
                mr={15}
                onClick={() => toggleColorScheme()}
                title="Toggle color scheme"
              >
                {isDark ? (
                  <IconBulb color="orange" />
                ) : (
                  <IconBulbOff color={theme.fn.primaryColor()} />
                )}
              </ActionIcon>
              <Tooltip
                color="#3A384E"
                label={
                  <div>
                    <p className={classes.toolTipText}>
                      {userDetails.firstName} {userDetails.lastName}
                    </p>
                  </div>
                }
              >
                <div className={classes.profileImg}>
                  {getImage(userDetails)}
                </div>
              </Tooltip>

              {(location.pathname === '/cash' ||
                location.pathname === '/simulations' ||
                location.pathname === '/inflows' ||
                location.pathname === '/outflows') && (
                <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                  <IconFilter
                    onClick={() => setEnabledModal(!enabledModal)}
                    color={theme.colors.gray[4]}
                    style={{ marginRight: rem(10) }}
                  />
                </MediaQuery>
              )}
            </Flex>
          </Grid.Col>
        </Grid>
        {(location.pathname === '/cash' ||
          location.pathname === '/inflows' ||
          location.pathname === '/outflows') && (
          <>
            <Grid className="w-100 filter_container" p="md" pt="0">
              <Grid.Col
                span={6}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: rem(200),
                }}
              >
                <LevelMenu onChangeLevel={setSelectedLevel} />
              </Grid.Col>

              <Grid.Col span={6}>
                <FilterMenu onChangeFilter={handleChangeDate}></FilterMenu>
              </Grid.Col>
            </Grid>
          </>
        )}
      </Header>
    </>
  )
}
