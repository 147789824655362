const loadUmamiScript = () => {
  const scriptUrl = window._env_.REACT_APP_UMAMI_SCRIPT_URL
  const websiteId = window._env_.REACT_APP_UMAMI_WEBSITE_ID

  const umamiScript = document.createElement('script')
  umamiScript.src = scriptUrl
  umamiScript.async = true
  umamiScript.setAttribute('data-website-id', websiteId)
  document.body.appendChild(umamiScript)

  return () => {
    document.body.removeChild(umamiScript)
  }
}

export default loadUmamiScript
