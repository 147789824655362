import React, { Suspense, useContext, useEffect, useState } from 'react'
import {
  ColorScheme,
  ColorSchemeProvider,
  Loader,
  MantineProvider,
} from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { Route, Routes } from 'react-router-dom'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { CashProvider, RBACProvider } from './context/CashRBAContext'
import AuthenticationStatus from './Keycloak/AuthenticationStatus'
import { KeycloakContext } from './Keycloak/KeycloakProvider'
import { DashboardLayout } from './layouts/mvue-layout/MVueLayout'
import PermittedRoutes from './components/PagelevelPermissions/PermittedRoutes'

import './index.css'
import { LicenseManager } from 'ag-grid-enterprise'
import { getLocal, setLocal } from './apputils'
import { AssistPage } from './pages/AssistPage'
import loadUmamiScript from './umamiConfig'

const HomePage = React.lazy(() => import('./pages/HomePage'))
const SupportPage = React.lazy(() => import('./pages/SupportPage'))
const KpiPage = React.lazy(() => import('./pages/KpiPage'))
const PredictionsPage = React.lazy(() => import('./pages/PredictionsPage'))
const PrivacyPage = React.lazy(() => import('./pages/PrivacyPage'))
const UploadPage = React.lazy(() => import('./pages/UploadPage'))
const Outflows = React.lazy(() => import('./pages/OutflowsPage'))
const PurchaseOrder = React.lazy(() => import('./pages/PurchaseOrder'))
const Inflows = React.lazy(() => import('./pages/InflowsPage'))
const AdminPage = React.lazy(() => import('./pages/AdminPage'))
const BankPage = React.lazy(() => import('./pages/BankPage'))
const Cashflow = React.lazy(() => import('./pages/Cashflow'))
const CashPage = React.lazy(() => import('./pages/CashPage'))
const BankOverview = React.lazy(() => import('./pages/BankOverview'))
const BankRegion = React.lazy(() => import('./pages/BankRegion'))

// LicenseManager.setLicenseKey(
//   'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-059377}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Tradefull}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{tradefull-connect}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{tradefull-connect}_need_to_be_licensed___{tradefull-connect}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{2_May_2025}____[v3]_[0102]_MTc0NjE0MDQwMDAwMA==de24b8f0b65a79482ca24a84dd098b2a'
// )

export default function MainApp() {
  const { error, authenticated, loading, keycloakValue, initialized } =
    useContext(KeycloakContext)

  const defaultColor = getLocal('colorScheme') === 'dark' ? 'dark' : 'light'
  const [colorScheme, setColorScheme] = useState<ColorScheme>(defaultColor)
  const [primaryColor, setPrimaryColor] = useState<string>('dark')

  const toggleColorScheme = (value?: ColorScheme) => {
    const newColorScheme = value || (colorScheme === 'dark' ? 'light' : 'dark')
    setColorScheme(newColorScheme)
    setPrimaryColor(newColorScheme === 'dark' ? 'light' : 'dark')
  }

  useEffect(() => {
    const cleanupScript = loadUmamiScript()
    return () => {
      if (cleanupScript) cleanupScript()
    }
  }, [])

  useEffect(() => {
    setLocal('colorScheme', colorScheme)
  }, [colorScheme])

  if (!initialized || loading) {
    return <Loader />
  }

  if (!keycloakValue || !authenticated) {
    return (
      <AuthenticationStatus
        loading={loading}
        error={error}
        authenticated={authenticated}
      />
    )
  }

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        withCSSVariables
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colors: {
            dark: [
              '#FFFFFF',
              '#acaebf',
              '#8c8fa3',
              '#666980',
              '#4d4f66',
              '#34354a',
              '#1D1E2D',
              '#1d1e30',
              '#242239',
              '#01010a',
            ],
            light: [
              '#000000',
              '#f8f9fa',
              '#7FD5C7',
              '#5DCAB9',
              '#40C1AC',
              '#36A593',
              '#4E5D78',
              '#000000',
              '#21655A',
              '#1C564D',
            ],
            collection: [
              '#3383FB4D',
              '#8A94A6',
              'cyan',
              'yellow',
              'Graytext',
              'rgba(141, 247, 188, 0.17)',
              'rgba(141, 247, 188, 0.33)',
              'rgba(141, 247, 189, 1)',
              'rgba(155, 0, 0, 0.33)',
              'indigo',
            ],
            cash: [
              '#A715DA',
              '#1976D2',
              '#6FA0E1',
              '#CC62FF',
              '#385C64',
              '#24343C',
            ],
            inflow: [`linear-gradient(to right, ${'#64B5F6'}, ${'#1976D2'})`],
            background: ['#e4ebf1'],
            light_gray: ['#fcfcfc'],
            landing: ['#E8F1ED', '#187A66'],
            landing_stats: ['#04454E'],
          },
          primaryColor: primaryColor,
          defaultGradient: { from: 'cyan', to: 'yellow' },
          colorScheme: colorScheme,
        }}
      >
        <Notifications position="top-right" zIndex={2077} />
        <CashProvider>
          <RBACProvider>
            <DashboardLayout>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="support" element={<SupportPage />} />
                  <Route
                    path="home"
                    element={
                      <PermittedRoutes path="home">
                        <HomePage />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="cash"
                    element={
                      <PermittedRoutes path="cash">
                        <CashPage />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="inflows"
                    element={
                      <PermittedRoutes path="inflows">
                        <Inflows />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="outflows"
                    element={
                      <PermittedRoutes path="outflows">
                        <Outflows />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="bankoverview"
                    element={
                      <PermittedRoutes path="bankoverview">
                        <BankOverview />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="bankregion"
                    element={
                      <PermittedRoutes path="bankregion">
                        <BankRegion />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="simulations"
                    element={
                      <PermittedRoutes path="simulations">
                        <PredictionsPage />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="cashflow"
                    element={
                      <PermittedRoutes path="cashflow">
                        <Cashflow />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="bank"
                    element={
                      <BankPage
                        entities={[]}
                        isAddBank={false}
                        isEditBank={false}
                      />
                    }
                  />
                  <Route
                    path="upload"
                    element={
                      <UploadPage
                        PermissionToUpload={false}
                        PermissionUploadHistory={false}
                        uploadEntities={[]}
                      />
                    }
                  />
                  <Route
                    path="kpi"
                    element={
                      <PermittedRoutes path="kpi">
                        <KpiPage />
                      </PermittedRoutes>
                    }
                  />
                  <Route
                    path="admin"
                    element={
                      <PermittedRoutes path="admin">
                        <AdminPage />
                      </PermittedRoutes>
                    }
                  />
                  <Route path="privacy" element={<PrivacyPage />} />
                  <Route
                    path="purchaseOrder"
                    element={
                      <PermittedRoutes path="purchaseOrder">
                        <PurchaseOrder />
                      </PermittedRoutes>
                    }
                  />
                  <Route path="assist" element={<AssistPage />} />
                </Routes>
              </Suspense>
            </DashboardLayout>
          </RBACProvider>
        </CashProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
