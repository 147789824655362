import { FC } from 'react'

import {
  AppShell,
  Header,
  Footer,
  useMantineTheme,
  useMantineColorScheme,
} from '@mantine/core'

import './MVueLayout.css'
import { DashboardNavbar } from './MvueNavbar'
import { DashboardFooter } from './MVueFooter'
import { DashboardHeader } from './MVueHeader'
import { useDisclosure } from '@mantine/hooks'
import { Outlet, useLocation } from 'react-router-dom'
//import LevelMenu from "../../components/FilterMenu/LevelMenu";

// Define props for the DashboardLayout component
interface LayoutProps {
  children: any
  // You can define any props that the component may receive here
  // For example, setSelectedLevel: (value: string) => void
}

export const DashboardLayout: FC<LayoutProps> = ({ children }) => {
  // Access the Mantine theme
  const theme = useMantineTheme()
  // Use the `useDisclosure` hook to manage the opening and closing of the sidebar
  const [opened, handlers] = useDisclosure(false)
  const location = useLocation()
  const getHeaderHeight = () => {
    if (
      location.pathname === '/cash' ||
      location.pathname === '/simulations' ||
      location.pathname === '/inflows' ||
      // location.pathname === "/kpi" ||
      location.pathname === '/outflows'
    ) {
      return 130
    } else {
      return 65
    }
  }
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const isDark = colorScheme === 'dark'

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="xs"
      asideOffsetBreakpoint="xs"
      layout="alt"
      navbar={
        // Include the DashboardNavbar component as the application's navbar
        <DashboardNavbar
          handlers={handlers}
          className="dashboard-navbar"
          opened={opened}
        ></DashboardNavbar>
      }
      footer={
        // Include the DashboardFooter component as the application's footer
        <Footer
          height={40}
          p="md"
          withBorder={false}
          className="footer-container"
        >
          <DashboardFooter className="footer"></DashboardFooter>
        </Footer>
      }
      header={
        // Include the DashboardHeader component as the application's header
        <Header
          height={{ base: 55, md: getHeaderHeight() }}
          // p="md"
          withBorder={false}
          className="header-container"
          bg={isDark ? theme.colors.dark[8] : theme.colors.light[1]}
        >
          <>
            <DashboardHeader
              opened={opened}
              handlers={handlers}
              className="header"
            ></DashboardHeader>
          </>
        </Header>
      }
    >
      {/* Use the Outlet component to render nested routes */}
      {/* <Outlet /> */}
      {children}
    </AppShell>
  )
}
