import {
  createStyles,
  getStylesRef,
  Group,
  Navbar,
  NavLink,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core'
import {
  IconBuildingBank,
  IconLifebuoy,
  IconLogout2,
} from '@tabler/icons-react'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { KeycloakContext } from '../../Keycloak/KeycloakProvider'
import { getAllEntities } from '../../apputils'
import LogoSmallLight from '../../assets/favicon-light.png'
import LogoLight from '../../assets/logo_light.png'
import { RBACContext } from '../../context/CashRBAContext'
import { EntitiesPermission } from '../../types'
import CustomIcon from './CustomIcon'
import { LinksGroup } from './NavbarLinksGroup'
import './styles.css'
import trackCustomEvent from '../Tracking/customUmamiTracker'
// Define CSS styles using Mantine's createStyles function
const useStyles = createStyles((theme) => ({
  navbar: {
    // backgroundColor: "#1A182F", // Set the background color to primaryColor
    color: theme.white, // Set the text color to white
    transition: '0.3s all',
    zIndex: 999,
    width: '80px !important',
    minWidth: '80px !important',
    '.mantine-ajwi11': {
      // visibility: "hidden",
      display: 'none',
    },
    '.mantine-pr1x6c>svg': {
      // visibility: "hidden",
      display: 'none',
    },
    '.mantine-1avyp1d': {
      display: 'none',
    },
    '.logo-image': {
      backgroundImage:
        'url(' + `${window._env_.REACT_APP_IMAGES_URL}/favicon.png` + ')',
      marginTop: '20px',
      marginBottom: '20px',
    },
    '.logo-image-light': {
      backgroundImage: 'url(' + LogoSmallLight + ')',
      marginTop: '20px',
      marginBottom: '20px',
    },
    '.mantine-Group-root': {
      borderTop: 'none',
    },
    '.mantine-hu7hcw>span': {
      display: 'none',
    },
    '.mantine-1d7pf7a>span': {
      display: 'none',
    },
    '.mantine-si3bcg': {
      borderTop: '1px solid white', //border change
    },
    '.user-details': {
      visibility: 'hidden',
    },

    '&:hover': {
      transition: '0.3s all',
      width: '320px !important',
      minWidth: '320px !important',

      '.mantine-ajwi11': {
        // visibility: "visible",
        display: 'block',
      },
      '.mantine-pr1x6c>svg': {
        // visibility: "visible",
        display: 'block',
      },
      '.logo-image': {
        backgroundImage:
          'url(' + `${window._env_.REACT_APP_IMAGES_URL}/logo.png` + ')',
      },
      '.logo-image-light': {
        backgroundImage: 'url(' + LogoLight + ')',
      },
      '.mantine-1avyp1d': {
        display: 'block',
      },
      '.mantine-13acbjj': {
        borderBottom: '0.0625rem solid #adb5bd;',
      },
      '.mantine-hu7hcw>span': {
        display: 'block',
      },
      '.mantine-1d7pf7a>span': {
        display: 'block',
      },
      '.mantine-si3bcg': {
        borderTop: '0.0625rem solid #adb5bd',
      },
      '.user-details': {
        visibility: 'visible',
      },
    },
  },

  logoImage: {
    height: rem(32),
    marginBottom: '20%',
    marginTop: '20%',
    marginLeft: '10px',
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
  },

  version: {
    backgroundColor: theme.white, // Lighten the primaryColor for the version background
    color: theme.white,
    fontWeight: 700,
  },

  header: {
    color: theme.white, // Set header text color to a shade of the primaryColor
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    // borderBottom: `${rem(1)} solid ${theme.colors.gray[5]}`, // Set the header border color to a shade of the primaryColor
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    // borderTop: `${rem(1)} solid ${
    //       theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[5]
    //     }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    // color: theme.white, // Set link text color to a shade of the primaryColor
    padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
    borderRadius: theme.radius.md,
    fontWeight: 400,

    '&:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        color: 'lightgray',
      }).background,

      color: 'white', // Set link hover text color to a shade of the primaryColor
      [`& .${getStylesRef('icon')}`]: {
        color: theme.fn.primaryColor(), // Set link hover icon color to a shade of the primaryColor
      },
    },
  },
  label: {
    color: 'white',
  },

  linkIcon: {
    // ref: getStylesRef("icon"),
    color: theme.white, // Set link icon color to a shade of the primaryColor
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.primaryColor(),
      color: '#fff', // Set active link text color to a shade of the primaryColor
      [`& .${getStylesRef('icon')}`]: {
        color: 'white', // Set active link icon color to a shade of the primaryColor
      },
    },
  },

  userIconContainer: {
    position: 'relative',
  },

  userIcon: {
    width: rem(40),
    height: rem(40),
    borderRadius: '50%',
    backgroundColor: theme.colors.light[9],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.fontSizes.md,
    color: theme.white,
  },

  userName: {
    marginLeft: theme.spacing.md,
    fontSize: theme.fontSizes.xm,
  },

  userEmail: {
    marginLeft: theme.spacing.md,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5], // Set user email text color to a shade of the primaryColor
  },

  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },

  profileIcon: {
    // Styles for the profile icon (initially IconUser, will be replaced with your custom image)
    fontSize: theme.fontSizes.md,
    color: theme.white,
    backgroundColor: theme.colors.teal,
    width: rem(30),
    height: rem(25),
    borderRadius: '45%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export function NavbarSimple({ opened = false }) {
  const location = useLocation()
  const { colorScheme } = useMantineColorScheme()
  const { logout } = useContext(KeycloakContext)
  const { accessPermissions } = useContext(RBACContext)
  const { classes, cx } = useStyles()

  const initPermission = {
    HomePage: [],
    CashPage: [],
    InflowsPage: [],
    OutflowsPage: [],
    SimulationPage: [],
    CashFlowPage: [],
    KPIPage: [],
    AdminPage: [],
    POPage: [],
    Viewbankoverview: [],
    ViewBankpage: [],
  }

  const [permissions, setPermissions] =
    useState<EntitiesPermission>(initPermission)

  const [loading, setLoading] = useState(true)

  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    user_role: [],
    user_status: '',
    user_image: '',
    u_id: '',
  })
  const [selectedPath, setSelectedPath] = useState<string>()

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    const permissions = getAllEntities(initPermission, accessPermissions)
    setPermissions(permissions)
    setLoading(false)
  }, [])

  const isDark = colorScheme === 'dark'
  useEffect(() => {
    const currentPath = location.pathname
    setSelectedPath(currentPath)
  }, [location.pathname])

  // Fetch user details from localStorage
  useEffect(() => {
    // Retrieve user details from state
    const storedUserDetails = localStorage.getItem('userDetails')
    if (storedUserDetails) {
      // setUserDetails(JSON.parse(storedUserDetails));
      const userDetails = JSON.parse(storedUserDetails)
      setUserDetails(userDetails.results)
    }
  }, [])

  // Handle user logout
  const handleLogout = () => {
    trackCustomEvent({ eventName: 'User Logout' })
    logout()
  }

  const HomeIcon = () => {
    if (selectedPath === '/home')
      return <CustomIcon src="Selecthome.svg" alt="home icon" />
    else return <CustomIcon src="homeicon.svg" alt="home icon" />
  }
  const CashIcon = () => {
    if (
      selectedPath === '/cash' ||
      selectedPath === '/inflows' ||
      selectedPath === '/outflows' ||
      selectedPath === '/bankregion' ||
      selectedPath === '/bankoverview' ||
      selectedPath === '/simulations' ||
      selectedPath === '/cashflow'
    )
      return <CustomIcon src="Selectcash.svg" alt="cash icon" />
    else return <CustomIcon src="cashicon.svg" alt="cash icon" />
  }
  const ConnectorIcon = () => {
    if (selectedPath === '/connector' || selectedPath === '/upload')
      return <CustomIcon src="Selectconnector.svg" alt="connector icon" />
    else return <CustomIcon src="connectoricon.svg" alt="connector icon" />
  }
  // const BankIcon = () => {
  //   if (selectedPath === "/bank")
  //     return <CustomIcon src="Selectbank.svg" alt="bank icon" />;
  //   else return <CustomIcon src="bankicon.svg" alt="bank icon" />;
  // };
  const InsightsIcon = () => {
    if (selectedPath === '/kpi')
      return <CustomIcon src="SelectInsights.svg" alt="kpi icon" />
    else return <CustomIcon src="insights-icon.svg" alt="kpi icon" />
  }
  const SupportIcon = () => {
    if (selectedPath === '/support')
      return <CustomIcon src="Selectsupport.svg" alt="support icon" />
    else return <CustomIcon src="supporticon.svg" alt="support icon" />
  }

  const AdminIcon = () => {
    if (selectedPath === '/admin')
      return <CustomIcon src="SelectAdmin.svg" alt="Admin icon" />
    else return <CustomIcon src="Admin.svg" alt="Admin icon" />
  }

  // const PurchaseOrderIcon = () => {
  //   if (selectedPath === "/purchaseOrder")
  //     return <CustomIcon src="PurchaseOrder.png" alt="Purchase icon" />;
  //   else return <CustomIcon src="PurchaseOrder.png" alt="Purchase icon" />;
  // };

  const PurchaseOrderIcon = () => {
    if (selectedPath === '/purchaseOrder')
      return <CustomIcon src="SelectIM.svg" alt="Purchase icon" />
    else return <CustomIcon src="IMIcon.svg" alt="Purchase icon" />
  }

  const LogoutIcon = () => {
    if (selectedPath === '/logout')
      return <CustomIcon src="Selectlogout.svg" alt="logout icon" />
    else return <CustomIcon src="Selectlogout.svg" alt="logout icon" />
  }

  // Define data for the navigation links
  const data = [
    {
      label: 'Home',
      icon: HomeIcon,
      link: '/home',
      enabled: permissions.HomePage.length > 0 ? true : false,
    },
    {
      label: 'Cash',
      icon: CashIcon,
      initiallyOpened: false,
      link: '/cash',
      enabled: permissions.CashPage.length > 0 ? true : false,
      activeClass:
        location.pathname === '/inflows' ||
        location.pathname === '/outflows' ||
        location.pathname === '/simulations',
      links: [
        {
          label: 'Inflows',
          link: '/inflows',
          enabled: permissions.InflowsPage.length > 0 ? true : false,
        },
        {
          label: 'Outflows',
          link: '/outflows',
          enabled: permissions.OutflowsPage.length > 0 ? true : false,
        },
        {
          label: 'Banks',
          link: '/bankregion',
          enabled: permissions.ViewBankpage.length > 0 ? true : false,
        },
        {
          label: 'Bank Transactions',
          link: '/bankoverview',
          enabled: permissions.Viewbankoverview.length > 0 ? true : false,
        },
        {
          label: 'Simulations',
          link: '/simulations',
          // enabled: ViewPredictionPage,
          enabled: permissions.SimulationPage.length > 0 ? true : false,
        },
        {
          label: 'CashFlow',
          link: '/cashflow',
          enabled: permissions.CashFlowPage.length > 0 ? true : false,
        },
      ],
    },
    // {
    //   label: "Connectors",
    //   icon: ConnectorIcon,
    //   link: "/connector",
    //   activeClass:
    //     location.pathname === "/cloud" || location.pathname === "/upload",
    //   links: [{ label: "Upload", link: "/upload" }],
    // },

    // { label: 'Banks', icon: <IconBuildingBank />, link: '/bankregion' },

    {
      label: 'KPI',
      icon: InsightsIcon,
      link: '/kpi',
      enabled: permissions.KPIPage.length > 0 ? true : false,
    },

    {
      label: 'Admin',
      icon: AdminIcon,
      link: '/admin',
      enabled: permissions.AdminPage.length > 0 ? true : false,
    },

    {
      label: 'Invoice\u00A0Match',
      icon: PurchaseOrderIcon,
      link: '/purchaseOrder',
      enabled: permissions.POPage.length > 0 ? true : false,
      activeClass: 'purcase-order',
    },
  ]

  const bottomData = [
    //  { label: "Support", icon: SupportIcon, link: "/support" },
    {
      label: 'Logout',
      icon: LogoutIcon,
      link: '/',
      activeClass: '/' === location.pathname,
      onClick: handleLogout,
      color: 'red',
      enabled: true,
    },
  ]

  const theme = useMantineTheme()

  // Support link in the navbar
  const supportLink = (
    <NavLink
      key="Support"
      label="Support"
      icon={
        <IconLifebuoy
          color={isDark ? theme.colors.dark[0] : 'gray'}
          size="1.5rem"
        />
      }
      onClick={() => navigate('/support')}
      color={isDark ? theme.colors.dark[0] : theme.colors.light[0]}
      className={cx(classes.link, {
        [classes.linkActive]: '/support' === location.pathname,
      })}
    />
  )

  // Logout link in the navbar
  const powerLink = (
    <NavLink
      ml={0}
      key="Logout"
      label="Logout"
      icon={<IconLogout2 color="#F68080" size="1.5rem" />}
      className={cx(classes.link, {
        [classes.linkActive]: '/' === location.pathname,
      })}
      onClick={handleLogout}
    />
  )

  const getLinks = () => {
    const new_links: any = data

    return new_links.map((item: any) => {
      return (
        // Create navigation links using LinksGroup component
        <LinksGroup
          {...item}
          key={item.label}
          activeLink={location.pathname}
          activeClass={item.activeClass}
        />
      )
    })
  }
  const handleLogoClick = () => {
    navigate('/home')
  }
  const links = data.map((item) => {
    return (
      // Create navigation links using LinksGroup component
      <LinksGroup
        {...item}
        key={item.label}
        activeLink={location.pathname}
        activeClass={item.activeClass}
      />
    )
  })

  const bottomLinks = bottomData.map((item) => {
    return (
      // Create navigation links using LinksGroup component
      <LinksGroup
        {...item}
        key={item.label}
        activeLink={location.pathname}
        activeClass={item.activeClass}
        onClick={item.onClick}
        color={item.color}
      />
    )
  })

  return (
    // Main navbar component
    <Navbar
      height="100%"
      width={{ sm: '80px' }}
      p="sm"
      className={classes.navbar}
    >
      <Navbar.Section grow>
        <Group className={classes.header} position="apart">
          {/* <img
            src={LogoSmall}
            alt="Logo"
            style={{
              height:  rem(32),
              marginBottom: "20%",
              marginTop: "20%",
              marginLeft: "5%",
            }}
          /> */}
          <div
            className={`${classes.logoImage} logo-image${
              isDark ? '' : '-light'
            }`}
            onClick={handleLogoClick}
            style={{ cursor: 'pointer' }}
          />
          {/* <div className={`${classes.logoImage} logo-image`}><Image src={isDark ? Logo : LogoLight}></Image></div> */}
          {/* <h3>by Calfus</h3> */}
        </Group>

        {!loading && getLinks()}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        {/* {supportLink} */}

        {bottomLinks}

        {/* Profile */}

        {/* <div className={cx(classes.link, classes.userIconContainer)}> */}
        {/* <div className={classes.profileContainer}> */}
        {/* <div className={classes.profileIcon}> */}
        {/* Replace IconUser with your CustomUserIcon */}

        {/* <CustomUserIcon />
            </div> */}
        {/* <div style={{ marginLeft: "10px" }} className="">
              <div> */}
        {/* Display first name and last name */}
        {/* {userDetails &&
                  `${userDetails.first_name} ${userDetails.last_name}`}
              </div>
              <div> */}
        {/* Display email address */}
        {/* {userDetails && userDetails.email_address}
              </div>
              <div></div>
            </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div>{powerLink}</div> */}
      </Navbar.Section>
    </Navbar>
  )
}

export default NavbarSimple
