declare global {
  interface Window {
    umami?: {
      track: (eventName: string, eventData?: any) => void
    }
  }
}

interface CustomEventData {
  eventName: string
  [key: string]: any
}

const MAX_EVENT_NAME_LENGTH = 50

const getToken = (): string | null => {
  return localStorage.getItem('accessToken')
}

const truncateEventName = (eventName: string): string => {
  if (eventName.length <= MAX_EVENT_NAME_LENGTH) {
    return eventName
  }
  const prefix = `Page: ${window.location.pathname} | `
  const availableLength = MAX_EVENT_NAME_LENGTH - prefix.length - 3

  return `${prefix}${eventName.substring(0, availableLength)}...`
}

const trackCustomEvent = async (eventData: CustomEventData) => {
  try {
    const token = getToken()
    if (!token) {
      console.error('No token available for event tracking')
      return
    }

    const tokenParts = token.split('.')
    const tokenPayload = JSON.parse(atob(tokenParts[1]))
    const firstname = tokenPayload.given_name || 'Unknown'

    if (!eventData.eventName) {
      console.error('Event name is required for tracking')
      return
    }

    const eventNameWithPage = `API GET - ${eventData.eventName}`
    const truncatedEventName = truncateEventName(eventNameWithPage)

    const eventDataWithUser = {
      ...eventData,
      username: firstname,
    }

    if (typeof window !== 'undefined' && window.umami) {
      window.umami.track(truncatedEventName, eventDataWithUser)
    } else {
      console.error('Umami is not available')
    }
  } catch (error) {
    console.error('Error tracking custom event:', error)
  }
}

export default trackCustomEvent
