import { jwtDecode } from 'jwt-decode'
import axios from 'axios'

const TOKEN_KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  EXPIRY_DATE: 'expiryDate',
  IS_AUTHENTICATED: 'isAuthenticated',
}

const KEYCLOAK_TOKEN_URL = `${window._env_.REACT_APP_KEYCLOAK_URL}/realms/${window._env_.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`

const getLocal = (key: string): string | null => localStorage.getItem(key)
const setLocal = (key: string, value: string): void =>
  localStorage.setItem(key, value)
const clearLocal = (key: string): void => localStorage.removeItem(key)

export const getToken = (): string | null => getLocal(TOKEN_KEYS.ACCESS_TOKEN)
export const getRefreshToken = (): string | null =>
  getLocal(TOKEN_KEYS.REFRESH_TOKEN)
export const setAccessToken = (token: string): void =>
  setLocal(TOKEN_KEYS.ACCESS_TOKEN, token)
export const setRefreshToken = (token: string): void =>
  setLocal(TOKEN_KEYS.REFRESH_TOKEN, token)

export const isTokenExpired = (token: string | null): boolean => {
  if (!token) return true

  const decodedToken: any = jwtDecode(token)
  if (!decodedToken.exp) {
    console.warn('Token does not contain an expiration time (exp)')
    return true
  }

  return decodedToken.exp < Math.floor(Date.now() / 1000)
}

export const isTokenExpiringSoon = (token: string | null): boolean => {
  if (!token) return true

  const decodedToken: any = jwtDecode(token)
  const expiringThreshold = 2 * 60
  const currentTime = Math.floor(Date.now() / 1000)
  return decodedToken.exp - currentTime < expiringThreshold
}

export const refreshTokenIfNeeded = async (): Promise<string | null> => {
  const token = getToken()

  if (!token || isTokenExpired(token) || isTokenExpiringSoon(token)) {
    const refreshToken = getRefreshToken()
    if (!refreshToken) {
      throw new Error('Refresh token not available')
    }

    try {
      const response = await axios.post(
        KEYCLOAK_TOKEN_URL,
        new URLSearchParams({
          client_id: window._env_.REACT_APP_KEYCLOAK_CLIENT_ID,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        })
      )

      const { access_token, refresh_token } = response.data
      setLocal(TOKEN_KEYS.ACCESS_TOKEN, access_token)
      setLocal(TOKEN_KEYS.REFRESH_TOKEN, refresh_token)
      console.log('Token refreshed successfully')

      return access_token
    } catch (error) {
      handleApiError(error)
    }
  }

  return token
}

export const clearTokens = (): void => {
  clearLocal(TOKEN_KEYS.ACCESS_TOKEN)
  clearLocal(TOKEN_KEYS.REFRESH_TOKEN)
  clearLocal(TOKEN_KEYS.EXPIRY_DATE)
  clearLocal(TOKEN_KEYS.IS_AUTHENTICATED)
}

export const validateTokens = async (): Promise<boolean> => {
  const token = getToken()
  const refreshToken = getRefreshToken()

  if (!token || !refreshToken) {
    clearTokens()
    return false
  }

  if (isTokenExpired(token)) {
    try {
      const newToken = await refreshTokenIfNeeded()
      return !!newToken
    } catch (error) {
      console.error('Error refreshing token:', error)
      clearTokens()
      return false
    }
  }

  return true
}

export const handleApiError = (error: any): void => {
  console.error(
    'API Error:',
    error.response ? error.response.data : error.message
  )
  throw new Error('API Error occurred')
}
