import React, { createContext, useEffect, useState } from 'react'
import { authorizedFetch } from '../apputils'

export interface Entity {
  entity: string
  id: string
}

interface Cash {
  dataIndex: number
  setDataIndex: (dataIndex: number) => void
  fromValue: Date
  setFromValue: (fromValue: Date) => void
  toValue: Date
  setToValue: (toValue: Date) => void
  toggleSidebar: boolean
  setToggleSidebar: (toggleSidebar: boolean) => void
}

interface RBAC {
  selectedLevel: Entity[]
  setSelectedLevel: (entity: Entity[]) => void
  accessPermissions: any
  businessEntity: Entity[]
  userImage: any
  globalRoleList: any[]
  PermissionEditUser?: any
  simulationEnabled?: any
}

export const CashContext = createContext<Cash>({} as Cash)
export const RBACContext = createContext<RBAC>({} as RBAC)

export const CashProvider = ({ children }: any) => {
  //sidebar amburer toggle
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(false)

  // Set the default to date as today
  const today = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate()
    )
  )

  // Set the default 'from' date to be 30 days prior to today
  const defaultFromDate = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate()
    )
  )
  defaultFromDate.setUTCDate(today.getUTCDate() - 30)

  // Set the default 'to' date to be 30 days after today
  const defaultToDate = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate()
    )
  )
  defaultToDate.setUTCDate(today.getUTCDate() + 30)
  const [dataIndex, setDataIndex] = useState<number>(1)
  const [fromValue, setFromValue] = useState<Date>(defaultFromDate)
  const [toValue, setToValue] = useState<Date>(defaultToDate)

  const defaultValue = {
    dataIndex,
    setDataIndex,
    fromValue,
    setFromValue,
    toValue,
    setToValue,
    toggleSidebar,
    setToggleSidebar,
  }

  return (
    <CashContext.Provider value={{ ...defaultValue }}>
      {children}
    </CashContext.Provider>
  )
}

export const RBACProvider = ({ children }: any) => {
  const [accessPermissions, setAccessPermissions] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState<Entity[]>([])
  const [businessEntity, setBussinessEntity] = useState<Entity[]>([])
  const [userImage, setUserImage] = useState('')
  const [globalRoleList, setGlobalRoleList] = useState([])
  const [loading, setloading] = useState(true)

  useEffect(() => {
    const fetchUser = async () => {
      setloading(true)
      const { userDetails = [] } = await authorizedFetch('/permissions')
      setBussinessEntity(userDetails[0].businessEntity)
      setAccessPermissions(userDetails[0].userRoles)
      setUserImage(userDetails[0].userImage)
      setGlobalRoleList(userDetails[0].globalRoleList)
      setSelectedLevel(userDetails[0].businessEntity)
      setloading(false)
    }
    fetchUser()
  }, [])

  if (loading) {
    return null
  }

  return (
    <RBACContext.Provider
      value={{
        accessPermissions,
        businessEntity,
        userImage,
        globalRoleList,
        selectedLevel,
        setSelectedLevel,
      }}
    >
      {children}
    </RBACContext.Provider>
  )
}
