import { Flex, Grid, Group, createStyles } from '@mantine/core'
import { FC, useEffect, useState } from 'react'
import { DateInput } from '@mantine/dates'
import { useMediaQuery } from '@mantine/hooks'
import trackCustomEvent from '../Tracking/customUmamiTracker'

// Styles for FilterMenu
const useStyles = createStyles((theme) => ({
  flexContainer: {
    justifyContent: 'flex-end',
  },
  dateInput: {
    maxWidth: 160,
    marginLeft: 'auto',
  },
  separator: {
    padding: theme.spacing.md,
  },
}))

// Define TypeScript interface for the component props
interface FilterMenuProps {
  className?: string
  onChangeFilter?: any
  isModal?: boolean
  isCanceled?: boolean
}

// Define the FilterMenu functional component
export const FilterMenu: FC<FilterMenuProps> = ({
  onChangeFilter,
  isModal,
  isCanceled,
}) => {
  const [period] = useState('day')
  const { classes } = useStyles()
  const today = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate()
    )
  )

  // Set the default 'from' date to be 30 days prior to today
  const defaultFromDate = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate()
    )
  )
  defaultFromDate.setUTCDate(today.getUTCDate() - 30)

  // Set the default 'to' date to be 30 days after today
  const defaultToDate = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate()
    )
  )
  defaultToDate.setUTCDate(today.getUTCDate() + 30)

  const [fromValue, setFromValue] = useState<Date | null>(defaultFromDate)
  const [toValue, setToValue] = useState<Date | null>(defaultToDate)

  // Call the onChangeFilter callback whenever any of the filter values change
  useEffect(() => {
    onChangeFilter(period, fromValue, toValue)
  }, [period, fromValue, toValue, onChangeFilter])

  useEffect(() => {
    if (isCanceled) {
      setFromValue(defaultFromDate)
      setToValue(defaultToDate)
      onChangeFilter(period, defaultFromDate, defaultToDate)
    }
  }, [isCanceled])

  const handleFromChange = (event: Date | null) => {
    setFromValue(event)
    trackCustomEvent({
      eventName: 'From Date Selection Updated',
    })
  }

  const handleToChange = (event: Date | null) => {
    setToValue(event)
    trackCustomEvent({
      eventName: 'To Date Selection Updated',
    })
  }

  return (
    <Grid.Col md={12} style={{ display: isModal ? 'flex' : 'block' }}>
      <Flex
        className={classes.flexContainer}
        // style={{ flexDirection: isSmallerScreen ? "column" : "row" }}
      >
        <Group position="center">
          <DateInput
            value={fromValue}
            maxDate={toValue || undefined}
            onChange={handleFromChange}
            placeholder="From Date"
            className={classes.dateInput}
            variant="filled"
          />
        </Group>
        <Flex className={classes.separator}>&nbsp;To:&nbsp;</Flex>
        <Group position="center">
          <DateInput
            mr="0.5rem"
            minDate={fromValue || undefined}
            value={toValue}
            onChange={handleToChange}
            placeholder="To Date"
            className={classes.dateInput}
            variant="filled"
          />
        </Group>
      </Flex>
    </Grid.Col>
  )
}
