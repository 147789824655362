import Keycloak from 'keycloak-js'

const keycloakUrl = window._env_.REACT_APP_KEYCLOAK_URL
const keycloakRealm = window._env_.REACT_APP_KEYCLOAK_REALM
const keycloakClientId = window._env_.REACT_APP_KEYCLOAK_CLIENT_ID

if (!keycloakUrl || !keycloakRealm || !keycloakClientId) {
  throw new Error(
    'Missing required Keycloak environment variables. Please ensure that REACT_APP_KEYCLOAK_URL, REACT_APP_KEYCLOAK_REALM, and REACT_APP_KEYCLOAK_CLIENT_ID are set in the .env file.'
  )
}

const keycloakInstance = new Keycloak({
  url: keycloakUrl,
  realm: keycloakRealm,
  clientId: keycloakClientId,
})

export default keycloakInstance
